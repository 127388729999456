'use client';

import * as Sentry from '@sentry/nextjs';
import ErrorPage from 'next/error';
import { useEffect } from 'react';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="fr">
      <body>
        <ErrorPage statusCode={undefined as unknown as number} />
      </body>
    </html>
  );
}
